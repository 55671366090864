const Menu = {
    init() {
        const menuToggler = document.querySelector('.js-menu-toggler');
        const menuText = document.querySelector('.js-menu-text');
        const menuContainer = document.querySelector('.js-menu-container');
        const body = document.querySelector('body');

        if (!menuToggler || !menuContainer) return;

        const mediaQuery = window.matchMedia('(max-width: 991px)');

        const closeMenu = () => {
            menuToggler.classList.remove('is-active');
            menuContainer.classList.remove('is-active');
            body.style.overflow = '';
            menuText.textContent = 'Menu';
        };

        const handleMenuToggle = function () {
            if (mediaQuery.matches) {
                menuToggler.addEventListener('click', function () {
                    this.classList.toggle('is-active');
                    menuContainer.classList.toggle('is-active');

                    if (this.classList.contains('is-active')) {
                        body.style.overflow = 'hidden';
                        menuText.textContent = 'Zamknij';
                    } else {
                        body.style.overflow = '';
                        menuText.textContent = 'Menu';
                    }
                });

                // Nasłuchuj kliknięć w linki z hashami w menu mobilnym
                menuContainer.addEventListener('click', e => {
                    const link = e.target.closest('a[href*="#"]');
                    if (link) {
                        closeMenu();
                    }
                });
            }
        };

        handleMenuToggle();

        mediaQuery.addEventListener('change', handleMenuToggle);
    },
};

export default Menu;
