import AOS from 'aos';
import Menu from './block/menu';
import SmoothScroll from './block/smooth-scroll';
import StickyHeader from './block/sticky-header';

var documentReady = function () {
    console.log('DOM Loaded');
    Menu.init();
    new StickyHeader();
    new SmoothScroll();

    AOS.init({
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
