export default class StickyHeader {
    constructor() {
        this.header = document.querySelector('header');
        this.lastScroll = 0;
        this.scrollThreshold = 100; // Minimalna odległość scrollu przed rozpoczęciem chowania
        this.init();
    }

    init() {
        if (!this.header) return;

        // Dodaj klasy początkowe
        this.header.classList.add('header--fixed');

        // Nasłuchuj scroll
        window.addEventListener('scroll', () => {
            this.handleScroll();
        });

        // Inicjalizuj stan początkowy
        this.handleScroll();
    }

    handleScroll() {
        const currentScroll = window.pageYOffset;

        // Pokaż header na samej górze strony
        if (currentScroll <= 0) {
            this.header.classList.remove('header--hidden', 'header--scrolled');
            return;
        }

        // Dodaj klasę scrolled gdy użytkownik przewinie stronę
        if (currentScroll > 0) {
            this.header.classList.add('header--scrolled');
        }

        // Nie wykonuj dalszej logiki jeśli scroll jest mniejszy niż próg
        if (currentScroll < this.scrollThreshold) {
            return;
        }

        // Określ kierunek scrollowania
        if (currentScroll > this.lastScroll) {
            // Scrollowanie w dół
            this.header.classList.add('header--hidden');
        } else {
            // Scrollowanie w górę
            this.header.classList.remove('header--hidden');
        }

        this.lastScroll = currentScroll;
    }
}
