export default class SmoothScroll {
    constructor() {
        this.init();
    }

    init() {
        document.addEventListener('click', e => {
            const link = e.target.closest('a[href*="#"]');
            if (!link) return;

            const hash = link.hash;
            const targetElement = document.querySelector(hash);

            if (targetElement) {
                e.preventDefault();

                const mobileMenu = document.querySelector(
                    '.mobile-menu.is-active'
                );
                if (mobileMenu) {
                    mobileMenu.classList.remove('is-active');
                    document.body.classList.remove('mobile-menu-active');
                }

                const header = document.querySelector('header');
                const headerOffset = header ? header.offsetHeight : 0;

                window.scrollTo({
                    top: targetElement.offsetTop - headerOffset,
                    behavior: 'smooth',
                });

                history.pushState(
                    '',
                    document.title,
                    window.location.pathname + window.location.search
                );
            }
        });

        window.addEventListener('load', () => {
            if (window.location.hash) {
                const targetElement = document.querySelector(
                    window.location.hash
                );
                if (targetElement) {
                    setTimeout(() => {
                        const header = document.querySelector('header');
                        const headerOffset = header ? header.offsetHeight : 0;

                        window.scrollTo({
                            top: targetElement.offsetTop - headerOffset,
                            behavior: 'smooth',
                        });

                        history.pushState(
                            '',
                            document.title,
                            window.location.pathname + window.location.search
                        );
                    }, 100);
                }
            }
        });
    }
}
